import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0078 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="遅刻早退時の賃金控除について、適用する際の注意事項などを解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2025.01.23</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働者からの労務提供を受けるにあたっては、労働時間に相当する分を対価として支払わなければなりません。もちろん、ただ支払えばよいということではなく、最低賃金法や労働基準法等の労働関係法令を踏まえての対応が必須となります。他方、働いていない分（例えば遅刻や早退があった場合)について賃金支払義務はありません。今回は遅刻早退時の賃金控除について解説します。
      </p>
      <img src="/images/column/details/c0078.jpg" alt="キンクラコラム画像78" />
      <div id="toc1">
        <h1>遅刻早退時とは</h1>
      </div>
      <p>
        まず、始業・終業時刻は契約形態によっても異なり、近年は「多様な働き方」が尊重される時代背景が到来していることもあり、様々な始業・終業時刻が存在します。例えばアルバイトなどの非正規雇用の場合は子供の保育園への送迎があり、始業は遅めの時間（例えば正社員は9時始業であるものの10時始業の契約)を希望することもあるでしょう。この場合、10時より前に遅刻という概念はありませんので、副次的に賃金控除の余地がありません。ただし、この会社の正社員の場合は、
        <span>
          9時が始業であることから遅刻して10時に出勤した場合は、1時間分は労務提供がありません
        </span>
        ので、「ノーワークノーペイの原則」によって1時間分の賃金控除が生じます。「ノーワークノーペイの原則」とは、端的には、働いていない時間分に限っては賃金支払い義務がないということです。上記の例は遅刻のケースでしたが、早退も労務提供がないという意味では同じ理屈であり、ノーワークノーペイの原則に則り、
        <span>
          早退によって労務提供のない時間分の賃金支払義務はありません。
        </span>
      </p>

      <div id="toc2">
        <h1>時間単位年休の申請があった場合</h1>
      </div>
      <p>
        もちろん、事前に時間単位年休（時間単位の年次有給休暇)の申請があった場合には現実に労務提供がないとはいえ、
        <span>賃金控除することは許されません。</span>
        理由として、有給休暇は労務提供がなくとも賃金保障しなければならないためです。
        <br />
        <br />
        ただし、時間単位年休は労使協定を締結し、かつ、年5日の範囲内での取得となりますので、最低賃金や割増賃金とは異なり、画一的に全ての事業場で対象となる制度ではありません。よくある事例で、中途採用者がたまたま前の会社で時間単位年休が制度として導入されており、その理解のまま（転職先でも制度として存在するものと認識)入社し、トラブルとなることがあります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <div id="toc3">
        <h1>賃金控除を適用する際の注意事項1</h1>
      </div>
      <p>
        完全に月給が保証されている完全月給者とは異なり、賃金控除が適用対象となる月給者の場合、言うまでもなく、通常支給されている額は「月額給」が支給されていますので、一般的に賃金控除の際には「時間給」を算出後に賃金控除を行う必要があります。時間給の算出にあたっては、一般的には1か月あたりの平均所定労働時間を算出後に時間給を算出するための基礎単価（例えば月額の基本給）を乗じて、時間給を算出します。これは割増賃金の基礎単価を算出する場合も同様の理屈ですが
        <span>
          割増賃金の場合は、時間給に対してさらに1.25倍しなければ法違反
        </span>
        となりますので、注意が必要です。
      </p>

      <div id="toc4">
        <h1>賃金控除を適用する際の注意事項2</h1>
      </div>
      <p>
        信義則的な意味合いも含まれていますが、賃金控除を行う場合には事前に予告することです。もちろん、会社の規模にもよりますし、ノーワークノーペイの原則に則り遅刻早退分の賃金控除は「当たり前」と捉えがちですが、打刻されている出退勤時刻とは別に遅刻早退の届出がされている場合で、乖離が生じている場合は賃金控除額が不適正な額となる可能性があるからです。もちろん大企業であれば社員数も数千人に達することも想定され、都度確認するには相当な労力を要しますので、現実的ではありません。しかし、その場合は時間単位年休が導入されている可能性が高く、時間単位年休は「5日以内」と規定されていますが、その範囲内という可能性も否定できません。給与計算は会社の心臓部であり、本来支給されるべき額よりも低い額で支給した場合、労使間の信頼関係に亀裂が生じる可能性があります。
        <span>
          賃金控除を行う際には時間単位年休の残は存在しないのか、打刻時間とは別に届け出は存在しないのかを確認後に実行
        </span>
        すべきです。
      </p>

      <div id="toc5">
        <h1>賃金控除を適用する際の注意事項3</h1>
      </div>
      <p>
        月給者の場合は、時間給に換算する「ひと手間」が生じますが、例えば手当が新設されている場合等、遅刻早退時に当該手当も賃金控除の対象になるのか確認が求められます。特に正社員の場合は、比較的に非正規雇用労働者よりも広範囲の業務に従事するという性質上、様々な手当が支給されていることが多く、
        <span>
          遅刻早退が生じた該当月に賃金控除漏れが生じていると、賃金計算としては誤り
        </span>
        になります。
      </p>

      <div id="toc6">
        <h1>最後に</h1>
      </div>
      <p>
        「賃金控除を適用する際の注意事項3」で述べた手当からの賃金控除は就業規則や賃金規程に明記があればそれに則った対応で足りますが、明確に記載がない場合や手当の新設が先行し、賃金控除の取り決めが不明瞭なケースもあります。このような場合は今回の取り扱いがその後の実務にも影響を与えるため、人事労務部門（企業によっては呼び名が異なる）として見解を出し、統一的な取り扱いをすることが求められます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0078
