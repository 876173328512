import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnDetailInner0078 from '../../../components/molecules/columnDetailInner0078'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import Conclusion001 from '../../../components/molecules/Conclusion001'
import Conclusion002 from '../../../components/molecules/Conclusion002'
import Conclusion003 from '../../../components/molecules/Conclusion003'
import Conclusion004 from '../../../components/molecules/Conclusion004'
import Conclusion005 from '../../../components/molecules/Conclusion005'
import Conclusion006 from '../../../components/molecules/Conclusion006'
import Conclusion007 from '../../../components/molecules/Conclusion007'
import Conclusion008 from '../../../components/molecules/Conclusion008'
import Conclusion009 from '../../../components/molecules/Conclusion009'
import CarouselBanner from '../../../components/molecules/carouselBanner'
import PopupBanner from '../../../components/molecules/popupBanner'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import UsefulFunctionLayout004 from '../../../components/molecules/usefulFunctionLayout004'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0078 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: '/column' },
    { title: 'コラム記事一覧', link: '/column/list' },
    {
      title: '遅刻早退時の賃金控除について、適用する際の注意事項などを解説！',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="遅刻早退時の賃金控除について、適用する際の注意事項などを解説！"
        description="遅刻早退時の賃金控除について、時間単位年休の申請があった場合や賃金控除を適用する際の注意事項を詳しく解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0078/"
        ogType="article"
        ogTitle="遅刻早退時の賃金控除について、適用する際の注意事項などを解説！"
        ogDescription="遅刻早退時の賃金控除について、時間単位年休の申請があった場合や賃金控除を適用する際の注意事項を詳しく解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/C0078/"
      />
      <ColumnDetailsBody>
        {/** ポップアップバナー */}
        <PopupBanner />
        {/** 記事本体 */}
        <ColumnDetailInner0078 no={no} />

        {/** ここに9パターンの広告設定する */}
        {no === 0 && <Conclusion001 />}
        {no === 1 && <Conclusion002 />}
        {no === 2 && <Conclusion003 />}
        {no === 3 && <Conclusion004 />}
        {no === 4 && <Conclusion005 />}
        {no === 5 && <Conclusion006 />}
        {no === 6 && <Conclusion007 />}
        {no === 7 && <Conclusion008 />}
        {no === 8 && <Conclusion009 />}

        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout004 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** カルーセルバナー */}
        <CarouselBanner />
        {/** 関連記事 */}
        {/* <ColumnRelationCards0078 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0078

export const pageQuery = graphql`
  query C0078 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
