import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0077 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="36協定に違反しないためには？協定違反となる事例集、違反しないための注意点などを解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.12.17</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        事業を営んでいくにあたっては労働者からの労務提供は絶対的に必要なものとなります。また、企業によって時期は異なるものの、繁忙期と閑散期があります。言うまでもなく繁忙期となれば閑散期とは圧倒的に異なり、労働時間が長くなるのが通常です。労働基準法では1日単位と週単位で働かせることができる時間が定められており、この時間を超える場合には労使合意を経て、36協定の届出が義務付けられています。今回は、36協定に違反しないための必要な手続きや抜け落ちがちな論点にフォーカスをあて解説します。
      </p>
      <img src="/images/column/details/c0077.jpg" alt="キンクラコラム画像77" />
      <div id="toc1">
        <h1>36協定とは</h1>
      </div>
      <p>
        労働基準法上、労働者が働くことができる限度として、
        <span>１日８時間・１週40時間以内とされています</span>
        （特定適用事業所は1週44時間）。これを「法定労働時間」と定義します。しかし、季節的な業務量の増加等により、法定労働時間を超えて労働者に時間外労働を担ってもらう必要性が高い場合が想定されます。そのような場合には、労働基準法第36条に基づく労使協定（これを36協定と呼ぶ）を締結し、企業の所轄労働基準監督署長へ届け出る必要があります。36協定は遡って届け出ることはできませんので、必ず事前に届け出なければなりません。
      </p>

      <div id="toc2">
        <h1>協定違反となる事例集</h1>
      </div>
      <p>
        36協定を締結するには民主的な手続きによって労働者代表を選任しなければなりません。
        <span>
          協定違反となる選出方法としては、事業主から直接的に指名を受けた場合
        </span>
        が挙げられます。このような選出方法では、言うまでもなく、真の意味で労働者の代表とは言い難く、事業主からの圧力によって労働時間の過少申告が余儀なくされるなど、正当な労務提供になり得ないことが想定されます。また、不信任決議と言い、複数名の代表者から代表に相応しくないものの表明を行い、代表に相応しくないという表が最も少なかった者が労働者代表に選任するという手法も民意が反映されているとは言えず不適切な選出方法となります。
        <br />
        <br />
        他方、労働者代表を選出する趣旨を明示し、選出方法の1つである「挙手」を選択したものの、まったく手が上がらずに再度労働者の中で話し合いの場を持ち、選出された場合は問題ありません。他には前述の「不信任決議」の逆のパターンとして信任決議（選挙で最もふさわしい代表者の選任を行う）の場合も問題ありません。
        <br />
        <br />
        また、
        <span>
          36協定締結後であっても、締結した時間数を超えて労働させる場合は協定違反
        </span>
        となります。締結にあたっては今後の事業動向も勘案して決定することが重要です。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <div id="toc3">
        <h1>違反しないための注意点</h1>
      </div>
      <div id="toc3-1">
        <h2>定期的な労働時間管理</h2>
      </div>
      <p>
        36協定は会社と労働者との間での時間外休日労働に関する協定です。すなわち会社が労働者に時間外休日労働を命じることができる上限と解されます。もちろん、繁忙に差し掛かると労働時間管理ばかりに時間を割くことはできず、顧客への対応や事業の枢要部となる業務に多くの時間を投入することが予想されます。すなわち、多くの企業で繁忙期になればなるほど労働時間管理は後回しになることが一般的です。36協定を締結したからと言って、青天井に時間外休日労働を命じることはできませんので、繁忙期であればはるほど、より注意して労働時間管理をしなければ法違反となってしまう可能性が高まります。このようなリスクに対処するには
        <span>
          システム上アラート機能を実装し、設定した労働時間数に達すると労働者と所属長宛に警告メッセージが表示されることで法違反を回避する
        </span>
        という労務管理手法があります。
      </p>
      <div id="toc3-2">
        <h2>業務分担</h2>
      </div>
      <p>
        属人化によって、特定の個人へ業務が集中してしまうことは多くの企業で起こっていることです。もちろん現在の労働市場は、終身雇用から雇用の流動化へとシフトチェンジしており、属人化した業務を担っていた労働者が退職してしまうと、業務運営に支障をきたす蓋然性が高くなります。また、それだけでなく、36協定は1人程度であれば締結した時間数を超えて労働させても差し支えないということはありませんので、法違反回避の観点からも俗人化は極めて危険と言えます。そして、
        <span>
          業務を分担することで、これまでになかった発想が生まれ、より生産性の高い業務運営が可能
        </span>
        となれば、より少ない時間での協定締結が可能となり、企業としても人件費をより抑えることが可能となります。
      </p>

      <div id="toc4">
        <h1>発覚した場合の対処方法</h1>
      </div>
      <p>
        もちろんあってはなりませんが、協定した時間を超えてしまった場合であっても記録（例えばタイムカードの）の改竄を行うことは許されません。
        <span>
          会社としては然るべき賃金計算を行い、当該賃金計算と紐づく給与支給日に通常の基本給と割増賃金を支払わなければなりません。
        </span>
        また、そのような状態が恒常的に続くようであれば協定時間の再考が望まれます。
        <br />
        <br />
        また、2023（令和5）年4月1日以降は中小企業であっても月に60時間を超える時間外労働があった場合には、25％ではなく、50％の割増率が適用されるように改正されています。この部分も抜け落ちることのないように賃金計算をしなければなりません。月60時間超の割増率の改正については、「代休」という選択肢もありますが、運用内容によっては労使協定の締結も必要となり、対応すべき範囲はより広範囲に及びます。
      </p>

      <div id="toc5">
        <h1>最後に</h1>
      </div>
      <p>
        36協定の違反は、大企業となれば報道されることにも繋がり、社会的にも影響が大きい部分です。また、是正指導の対象となる違反の中でも上位に位置することから、企業としてもより注視すべき部分です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0077
